import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
	agenttransactionhistoryDispatch,
	agenttransactionhistoryRailDispatch,
} from '../../reducers/UserReducer';
const tableHeaders = [
	'TYPE',
	'CART_ID',
	'DOJ',
	'COMMISSION',
	'MARKUP',
	'AMOUNT',
	'AVAL_BAL',
	'PAY_TYPE',
	'DATE',
	'HOLD_S',
	'TRANS_S',
	'DESC',
];

const TransactionHistoryRail = () => {
	const { transagent, agentlogin, loadinguser, transagentrail } = useSelector(
		(state) => state.user
	);
	const [val, setVal] = useState('');
	const [data, setData] = useState('');
	const [start, setSDate] = useState(new Date());
	const [end, setEDate] = useState(new Date());
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			agenttransactionhistoryRailDispatch(
				agentlogin && agentlogin.result.result.id
			)
		);
	}, []);
	let tranData = [];
	if (loadinguser) {
		console.log('first');
	} else {
		// tranData= transagent &&
		// transagent.result &&
		// transagent.result.result?.length > 0 &&
		// transagent.result.result?.slice().sort((a,b)=>{
		//   return a.id - b.id
		// })
	}
	// console.log('tranData', tranData);
	const handleDate = (event) => {
		// console.log("first",event.target.value)
		setSDate(new Date(event.target.value));
	};
	const handleDate2 = (event) => {
		// console.log("first2",event.target.value)
		setEDate(new Date(event.target.value));
	};
	const handleFilter = () => {
		let results = [];
		results =
			transagentrail &&
			transagentrail.result &&
			transagentrail.result.result.filter((item) => {
				var datef = new Date(item.date);
				return datef >= start && datef <= end;
			});
		setData(results);
		console.log('results', results);
	};
	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let results = [];
		console.log('filter', value);
		// setVal(value);

		if (event.target.value.toLowerCase() === 'new') {
			results =
				transagentrail &&
				transagentrail.result &&
				transagentrail.result.result?.length > 0 &&
				transagentrail.result.result?.slice().sort((a, b) => {
					return b.id - a.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'old') {
			results =
				transagentrail &&
				transagentrail.result &&
				transagentrail.result.result?.length > 0 &&
				transagentrail.result.result?.slice().sort((a, b) => {
					return a.id - b.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'lh') {
			results =
				transagentrail &&
				transagentrail.result &&
				transagentrail.result.result?.length > 0 &&
				transagentrail.result.result?.slice().sort((a, b) => {
					return a.amount - b.amount;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'hl') {
			results =
				transagentrail &&
				transagentrail.result &&
				transagentrail.result.result?.length > 0 &&
				transagentrail.result.result?.slice().sort((a, b) => {
					return b.amount - a.amount;
				});
			setData(results);
		} else {
			results =
				transagentrail &&
				transagentrail.result &&
				transagentrail.result.result.filter((item) => {
					return item.type.toLowerCase().includes(value.toLowerCase());
				});
			setData(results);
		}
		console.log('results', results);
	};
	console.log('Result', data);
	return (
		<>
			<div className='row ' style={{ padding: 15 }}>
				<Box display='flex' justifyContent='space-between'>
					{/* <h6 style={{ fontFamily: "Josefin Sans, sans-serif" }}>
          Transaction History
        </h6> */}
					<ReactHTMLTableToExcel
						id='test-table-xls-button'
						className='download-table-xls-button'
						style={{
							background: 'orange',
							color: 'white',
							paddingLeft: '5px',
							paddingRight: '5px',
						}}
						table='table-to-xls'
						filename='tablexls'
						sheet='tablexls'
						buttonText='Download as XLS'
					/>

					<Box>
						<input
							type='date'
							style={{
								padding: '13px',
								cursor: 'pointer',
								border: '1px solid',
							}}
							placeholder='Select Date'
							// value={val}
							onChange={handleDate}
							//   className="form-control form-control-sm"
						/>
						<input
							type='date'
							style={{
								padding: '13px',
								marginLeft: 10,
								cursor: 'pointer',
								border: '1px solid',
							}}
							placeholder='Select Date'
							// value={val}
							onChange={handleDate2}
							//   className="form-control form-control-sm"
						/>
						<span
							style={{
								marginLeft: 10,
								background: 'bisque',
								padding: 14,
								cursor: 'pointer',
								fontSize: 18,
								fontWeight: 'bold',
							}}
							onClick={handleFilter}>
							{' '}
							Search
						</span>
					</Box>
					<Box display='flex'>
						<Box display='flex' alignItems='center'>
							{/* <span>Filter</span> */}
							<select
								onChange={handleSearch}
								name='Filter by'
								id='cars'
								style={{ padding: 10, border: '1px solid grey' }}>
								<option value='booked'> Filter</option>
								<option value='CR'> Credit</option>
								<option value='DR'>Debit</option>
								{/* <option value="offline">Offline Payment</option>
              <option value="online">Online Payment</option>
              <option value="di">DI Amount</option> */}
							</select>
						</Box>
						<Box display='flex' alignItems='center' ml={'5px'}>
							{/* <span>Filter</span> */}
							<select
								onChange={handleSearch}
								name='Sort by'
								id='cars'
								style={{ padding: 10, border: '1px solid grey' }}>
								<option value='booked'> Sort</option>
								<option value='new'> Newest to Oldest</option>
								<option value='old'>Oldest to Newest</option>
								<option value='lh'>Price Low-High</option>
								<option value='hl'>Price High-Low</option>
							</select>
						</Box>
					</Box>
				</Box>
				<div className='col'>
					<table
						className='table bg-white rounded shadow-sm  table-hover'
						id='table-to-xls'>
						<thead>
							<tr>
								<th scope='col' width='50'>
									#
								</th>
								{tableHeaders.map((i) => {
									return (
										<th scope='col' style={{ fontFamily: 'monospace' }}>
											{i}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{data && data.length > 0
								? data.map((i, index) => {
										return (
											<tr
												style={{
													backgroundColor:
														i.type === 'CR' ? '#a4ffb2' : '#ffb2b2',
												}}>
												<th
													scope='row'
													style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.id}
												</th>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.type}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.cart_id}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.doj}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.commission}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.markup}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.amount}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.avail_bal}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.payment_type}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.date.split(' ')[0]}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.hold_status}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.trans_section}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.description}
												</td>
											</tr>
										);
								  })
								: transagentrail &&
								  transagentrail.result &&
								  transagentrail.result.result?.length > 0 &&
								  transagentrail.result.result?.map((i, index) => {
										return (
											<tr
												style={{
													backgroundColor:
														i.type === 'CR' ? '#a4ffb2' : '#ffb2b2',
												}}>
												<th
													scope='row'
													style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.id}
												</th>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.type}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.cart_id}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.doj}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.commission}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.markup}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.amount}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.avail_bal}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.payment_type}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.date.split(' ')[0]}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.hold_status}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.trans_section}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.description}
												</td>
											</tr>
										);
								  })}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default TransactionHistoryRail;
