import React, { useState } from 'react';
import {
	Avatar,
	Box,
	Hidden,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BodyText from './BodyText';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from './SuccessModal';
import {
	setForgot,
	setSection,
	toggleLoginModal,
	toggleRegisterModal,
	toggleRegisterTravellerModal,
} from '../../reducers/UiReducer';
import {
	agentloginDispatch,
	travellerloginDispatch,
	agentloginSuccess,
	supplierloginSuccess,
} from '../../reducers/UserReducer';
import ErrorModal from './ErrorModal';
import RegisterTravellerModal from './RegisterTravellerModal';
import Login from './Login';
const HeaderSection = ({ id }) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { walletbalanceRail } = useSelector((state) => state.user);
	const handleTheme = () => {
		let themeBtn = document.querySelector('#theme-btn');
		themeBtn.classList.toggle('fa-sun');

		if (themeBtn.classList.contains('fa-sun')) {
			document.body.classList.add('active');
		} else {
			document.body.classList.remove('active');
		}
	};

	const [user, setUser] = useState({
		username: '',
		password: '',
	});
	const { username, password } = user;
	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(agentloginDispatch(formData, history));
	};
	const onSubmit2 = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(travellerloginDispatch(formData, history));
	};
	const history = useHistory();
	const handleChange = (e) => {
		console.log(e.target.name[0]);
	};
	const { section } = useSelector((state) => state.ui);
	const { logo, admin } = useSelector((state) => state.home);
	const { travellerlogin, agentlogin, walletagent, supplierlogin } =
		useSelector((state) => state.user);
	const errorMessage = [
		'Techncal Error From Airline End',
		'Hello',
		'DDDDDDDDD',
		'HFJFFJ',
	];
	const handleMessage = () => {
		var i = Math.floor(errorMessage.length * Math.random());
		console.log('error', `${i} ${errorMessage[i]}`);
	};

	return (
		<>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					paddingRight: 15,
					paddingLeft: 15,
					marginRight: 'auto',
					marginLeft: 'auto',
				}}>
				<Box style={{ paddingBottom: 4.5, paddingTop: 4.5 }} id='mainheader'>
					<i
						class='fas fa-blender-phone'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.mobile_no}{' '}
					</span>
					<span style={{ display: 'inline-block', width: '1px' }}></span>
					<i
						class='fa fa-fw fa-envelope'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.email}
					</span>
				</Box>

				<Box style={{ marginRight: 15, cursor: 'pointer' }} id='mainheader2'>
					{agentlogin && agentlogin.status === 200 ? (
						<span style={{ marginRight: 10 }}>
							Rail Wallet : ₹ {walletbalanceRail?.result?.wallet_rail}
						</span>
					) : (
						''
					)}
					<span
						style={{ marginRight: 10 }}
						onClick={() => history.push('/dashboard')}>
						{agentlogin && agentlogin.status === 200
							? 'Dashboard'
							: supplierlogin && supplierlogin.status === 200
							? 'Dashboard'
							: ''}
					</span>
					<span
						style={{}}
						onClick={() => {
							dispatch(agentloginSuccess(null));
							dispatch(supplierloginSuccess(null));
							sessionStorage.removeItem('userInfoAgent');
							sessionStorage.removeItem('supplierlogin');
							history.push('/');
						}}>
						{agentlogin && agentlogin.status === 200
							? 'Signout'
							: supplierlogin && supplierlogin.status === 200
							? 'Signout'
							: ''}
					</span>
				</Box>
			</Box>
			<header className='header' id='headerticket'>
				<img
					style={{
						cursor: 'pointer',
						height: matches ? 30 : 45,
					}}
					src={logo?.result[0].logo}
					onClick={() => {
						history.push('/');
						handleMessage();
					}}></img>
				{/* </a> */}
				<Hidden only={['xs']}>
					<Box display='flex'>
						<Box
							display='flex'
							alignItems='center'
							onClick={() => {
								dispatch(setSection('flight'));
								history.push('/');
							}}>
							<i
								class='fas fa-plane-departure'
								style={{
									color: section === 'flight' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: section === 'flight' ? 'orange' : '#0A95FF',
									marginRight: 10,
									cursor: 'pointer',
								}}>
								Flight
							</span>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'20px'}
							onClick={() => {
								dispatch(setSection('bus'));
								history.push('/');
							}}>
							<i
								class='fas fa-bus'
								style={{
									color: section === 'bus' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: section === 'bus' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
								}}>
								Buses
							</span>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							onClick={() => {
								dispatch(setSection('hotel'));
								history.push('/');
							}}>
							<i
								class='fas fa-building'
								style={{
									color: section === 'hotel' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>

							<span
								style={{
									color: section === 'hotel' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
								}}>
								Hotels
							</span>
						</Box>

						{/* <Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'30px'}
							onClick={() => history.push('/')}>
							<a
								href='https://www.marinersforex.com/holiday-packages'
								target='_blank'>
								<i
									class='fas fa-umbrella-beach'
									style={{ color: '#0A95FF', cursor: 'pointer' }}></i>
								<span
									style={{
										color: '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Holidays
								</span>
							</a>
						</Box> */}
						<Hidden only={['xs']}>
							<Box display='flex' alignItems='center'>
								{travellerlogin && travellerlogin.status === 200 ? (
									<Box
										ml={'10px'}
										display='flex'
										alignItems='center'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push('/dashboard')}>
										<Avatar
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
											}}>
											{travellerlogin &&
												travellerlogin.result &&
												travellerlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : agentlogin && agentlogin.status === 200 ? (
									<Box
										style={{ alignItems: 'center', marginLeft: 10 }}
										display='flex'>
										<Box
											style={{
												marginRight: 10,
												fontSize: 16,
												cursor: 'pointer',
											}}
											onClick={() => history.push('/dashboard')}>
											{`Welcome ${
												agentlogin ? agentlogin?.result.result.agencyName : ''
											}[Agent Code : CNB10000${
												agentlogin ? agentlogin?.result.result.id : ''
											}]`}
										</Box>

										<Box style={{ fontSize: 16, marginRight: 15 }}>
											₹ Available Balance:{' '}
											{walletagent ? walletagent?.result.balanceAmount : ''}
										</Box>
										<Avatar
											onClick={() => history.push('/dashboard')}
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
												marginRight: 15,
												cursor: 'pointer',
											}}>
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : supplierlogin && supplierlogin.status === 200 ? (
									<Box
										style={{ alignItems: 'center', marginLeft: 10 }}
										display='flex'>
										<Box
											style={{
												marginRight: 10,
												fontSize: 19,
												cursor: 'pointer',
											}}
											onClick={() => history.push('/dashboard')}>
											{`Welcome ${
												supplierlogin ? supplierlogin?.result.result.name : ''
											}`}
										</Box>

										<Avatar
											onClick={() => history.push('/dashboard')}
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
												marginRight: 15,
												cursor: 'pointer',
											}}>
											{supplierlogin &&
												supplierlogin.result &&
												supplierlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : (
									<>
										<Box
											onClick={() => dispatch(toggleLoginModal(true))}
											style={{
												background: 'rgb(0, 55, 114)',
												color: 'white',
												padding: '8px',
												fontWeight: 'bold',
												fontSize: '.8em',
												cursor: 'pointer',
												marginLeft: 10,
											}}>
											Login or Create Account
										</Box>
									</>
								)}
							</Box>
						</Hidden>
					</Box>
				</Hidden>
				<Hidden only={['lg', 'xl', 'sm', 'md']}>
					<div className='icons'>
						{agentlogin && agentlogin.status === 200 ? (
							<Avatar
								onClick={() => history.push('/dashboard')}
								style={{
									color: 'white',
									background: 'rgb(239, 51, 57)',
									marginRight: 15,
									cursor: 'pointer',
									borderRadius: 15,
								}}>
								{agentlogin &&
									agentlogin.result &&
									agentlogin.result.result.name
										.match(/(\b\S)?/g)
										.join('')
										.match(/(^\S|\S$)?/g)
										.join('')
										.toUpperCase()}
							</Avatar>
						) : (
							<Box
								onClick={() => dispatch(toggleLoginModal(true))}
								style={{
									background: 'rgb(0, 55, 114)',
									color: 'white',
									// padding: '8px',
									fontWeight: 'bold',
									fontSize: '10px',
									cursor: 'pointer',
									width: '100%',
									paddingLeft: 5,
									paddingRight: 5,
								}}>
								<span>Login or Create Account</span>
							</Box>
						)}
					</div>
				</Hidden>

				<form
					onSubmit={(e) => onSubmit(e)}
					className='login-form'
					style={{ padding: 20 }}>
					<div className='inputBox'>
						<span>Username</span>
						<input
							type='email'
							name='username'
							style={{ fontSize: 14 }}
							value={username}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your name'
						/>
					</div>

					<div className='inputBox'>
						<span>Password</span>
						<input
							type='password'
							name='password'
							style={{ fontSize: 14 }}
							value={password}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your password'
						/>
					</div>

					<input
						onClick={() => {
							let loginForm = document.querySelector('.login-form');
							loginForm.classList.toggle('active');
						}}
						type='submit'
						className='btn'
						value='LOGIN'
						style={{ textAlign: 'center', fontSize: 14, borderRadius: 5 }}
					/>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<Box onClick={() => dispatch(toggleRegisterModal(true))}>
							{' '}
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 14,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								Signup
							</BodyText>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>

						<Box
							onClick={() => {
								history.push('/resetpassword');
								dispatch(setForgot('agent'));
							}}>
							<u>
								<BodyText
									textStyle={{
										// color: "#E61855",
										fontWeight: 600,
										marginTop: 10,
										fontSize: 13,
										cursor: 'pointer',
										textAlign: 'center',
									}}>
									Forgot Password ?
								</BodyText>
							</u>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>
					</Box>
				</form>
				<form
					onSubmit={(e) => onSubmit2(e)}
					className='login-form2'
					style={{ padding: 20 }}>
					<div className='inputBox2'>
						<span>Username</span>
						<input
							type='email'
							name='username'
							value={username}
							style={{ fontSize: 14 }}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your name'
						/>
					</div>

					<div className='inputBox2'>
						<span>Password</span>
						<input
							type='password'
							name='password'
							style={{ fontSize: 14 }}
							value={password}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your password'
						/>
					</div>

					<input
						onClick={() => {
							let loginForm = document.querySelector('.login-form2');
							loginForm.classList.toggle('active2');
						}}
						type='submit'
						className='btn'
						value='login'
						style={{ textAlign: 'center', fontSize: 14, borderRadius: 5 }}
					/>
					<Box
						alignItems='center'
						display='flex'
						justifyContent='space-between'>
						<Box onClick={() => dispatch(toggleRegisterTravellerModal(true))}>
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 14,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								Signup
								<Divider style={{ backgroundColor: '#E61855' }} />
							</BodyText>
						</Box>
						<Box
							onClick={() => {
								history.push('/resetpassword');
								dispatch(setForgot('traveller'));
							}}>
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 13,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								{' '}
								Forgot Password ?
							</BodyText>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>
					</Box>
				</form>
			</header>
			<RegisterTravellerModal />
			<SuccessModal />
			<ErrorModal />
			<Login />
		</>
	);
};

export default HeaderSection;
